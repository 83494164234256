body{
  font-family: 'Kumbh Sans';
  font-size: 12;
}

code{
  font-family: 'Kumbh Sans';
}

h1{
  font-family: 'Montserrat';

}
span{
  font-family: 'Kumbh Sans';

}
th{
    font-family: 'Kumbh Sans'; 
}
td{
  font-family: 'Kumbh Sans';

}
h2{
  font-family: 'Montserrat';

}
h3{
  font-family: 'Montserrat', sans-serif;

}
p{
  font-family: 'Kumbh Sans';
}

label{
  font-family: 'Kumbh Sans';
  font-weight: 600;
}
input{
  font-family: 'Kumbh Sans';
}
select{
  font-family: 'Kumbh Sans';
}
button{
  font-family: 'Kumbh Sans';
}